<template>
  <div class="homepage">
    <img
      src="../assets/profile.png"
      alt="Lionel Blessig - Fullstack Web Developer"
      title="Lionel Blessig - Fullstack Web Developer"
      class="homepage__profile"
    />
    <div class="homepage__title animate__animated animate__backInUp">
      Lionel Blessig
    </div>
    <div class="homepage__subtitle animate__animated animate__backInUp">
      FullStack Web Developer
    </div>
    <div class="homepage__technologies">
      <img
        src="../assets/laravel.svg"
        alt="Laravel"
        title="Laravel"
        class="homepage__technologies--item animate__animated animate__fadeInLeft"
        @mouseover="current_hover = 'laravel'"
        @mouseout="current_hover = null"
      />
      <img
        src="../assets/vue.svg"
        alt="Vue JS"
        title="Vue JS"
        class="homepage__technologies--item animate__animated animate__fadeInRight"
        @mouseover="current_hover = 'vue'"
        @mouseout="current_hover = null"
      />
      <img
        v-if="current_hover"
        :src="current_hover === 'vue' ? logos.vue_title : logos.laravel_title"
        :alt="current_hover === 'vue' ? 'Vue JS' : 'Laravel'"
        :title="current_hover === 'vue' ? 'Vue JS' : 'Laravel'"
        class="homepage__technologies--title animate__animated animate__fadeIn"
      />
    </div>
    <a href="/CV_Blessig_Lionel.pdf" target="_blank" class="homepage__cta"
      >Télécharger le CV</a
    >
  </div>
</template>

<script>
import { laravel, laravel_title, vue, vue_title } from "../assets";

export default {
  name: "Home",

  data() {
    return {
      logos: { laravel, laravel_title, vue, vue_title },
      current_hover: null
    };
  }
};
</script>

<style lang="scss">
.homepage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__profile {
    align-self: center;
    max-height: 250px;
  }

  &__title {
    font-family: "Oswald", sans-serif;
    font-size: 70px;
    text-transform: uppercase;
  }

  &__subtitle {
    font-family: "Cardo", serif;
    font-size: 40px;
    text-transform: uppercase;
  }

  &__technologies {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-around;
    position: relative;

    &--item {
      height: 120px;
    }

    &--title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40%;
    }
  }

  &__cta {
    text-decoration: none;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 30px;
    font-family: "Oswald", sans-serif;
    padding: 30px;
    border: 2px solid #ffffff;
    border-radius: 3px;
    transition: all 0.3s;

    &:hover {
      background: #ffffff;
      color: #000000;
    }
  }
}

@media screen and (max-width: 767px) {
  .homepage {
    justify-content: flex-start;

    &__profile {
      margin: 20px 0;
      width: 150px;
    }

    &__title {
      font-size: 40px;
      margin-bottom: 40px;
    }

    &__subtitle {
      font-size: 16px;
      margin-bottom: 40px;
    }

    &__technologies {
      margin-bottom: 40px;
      &--item {
        height: 50px;
      }
    }

    &__cta {
      font-size: 20px;
      padding: 15px;
    }
  }
}
</style>
