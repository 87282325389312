<template>
  <router-link :to="{ name: 'Home' }" class="logo">
    <img
      src="./assets/logo.svg"
      alt="Lionel Blessig - Fullstack Web Developer"
      title="Lionel Blessig - Fullstack Web Developer"
    />
  </router-link>
  <div class="content">
    <router-view />
  </div>
</template>

<style lang="scss">
@import "~normalize.css/normalize.css";
@import "~reset.css/reset.css";
@import url("https://fonts.googleapis.com/css2?family=Cardo&family=Oswald&display=swap");
@import "~animate.css/animate.min.css";

html,
body,
* {
  box-sizing: border-box;
}

#app {
  color: #ffffff;
  width: 100%;
  min-height: 100vh;
  background: url("./assets/bg.jpg");
  background-size: cover;
  display: flex;

  .logo {
    position: fixed;
    top: 5%;
    left: 5%;

    img {
      height: 50px;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5%;
  }
}

@media screen and (max-width: 767px) {
  #app {
    flex-direction: column;
    align-items: center;

    .logo {
      position: relative;
      top: initial;
      left: initial;
      margin: 15px 0;
    }

    .content {
      padding: 15px;
    }
  }
}
</style>
